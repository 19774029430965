import classes from "./Footer.module.css";
import logo from "../IMG//fulllogo.jpg";
import {Link} from "react-router-dom";
const Footer = () => {
    
    return(
    <>
    <footer className={classes.foot}>
        <ul className={classes.ul1}>
            <li><h1>WAGGING TAILS</h1></li>
            <li><i class="fa-solid fa-envelope"></i>      @gmail.com</li>
            <li><i class="fa-solid fa-location-dot"></i>      Dallas, TX</li>
            <li><i class="fa-sharp fa-solid fa-phone"></i>     +1 (972)-603-5330</li>
        </ul>
        <ul className={classes.ul2}>
            <li><h1>EXPLORE</h1></li>
            <li><Link  to="/">Homepage</Link></li>
            <li><Link  to="/services">Services</Link></li>
            <li><Link  to="/faqs">FAQs</Link></li>
            <li><Link  to="/contact-us">Contact Us</Link></li>
            <li><Link  to="/photo-gallery">Photo Gallery</Link></li>
        </ul>
        <img src={logo} alt="logo"/>
    </footer>
    <section className={classes.sect}>
        <h3>Copyright 2024 © Wagging Tails | All Rights Reserved.</h3>
        <article>
            <a href="wwww.facebook.com"><i className="fab fa-facebook-square"></i></a>
            <a href="www.instagram.com"><i className="fa-brands fa-instagram"></i></a>
        </article>
    </section>
    </> 
    )
};

export default Footer;