import { useState } from "react";
import DataContext from "./dataContext";
import gal1 from "../IMG/dog1.jpg";
import gal2 from "../IMG/dog2.jpg";
import gal3 from "../IMG/dog3.jpg";
import gal4 from "../IMG/dog4.jpg";
import gal5 from "../IMG/dog5.jpg";
import gal6 from "../IMG/dog6.jpg";
import gal7 from "../IMG/dog7.png";
import classes from "../routes/PhotoGallery.module.css";

const DataProvider = (props) => {

    const gallery = [
        {id:"gal1", pic: gal1, style: classes.class1},
        {id:"gal2", pic: gal2, style: classes.class2},
        {id:"gal3", pic: gal3, style: classes.class3},
        {id:"gal4", pic: gal4, style: classes.class4},
        {id:"gal5", pic: gal5, style: classes.class5},
        {id:"gal6", pic: gal6, style: classes.class6},
        {id:"gal7", pic: gal7, style: classes.class7},
    ];

    const [modal, setModal] = useState(true);

    const modalHandler = () => {
        setModal((prev)=>{
            return prev?!prev:prev;
        })
    }

    const data = {
        modal,
        modalHandler,
        gallery
        
    }

    return(
        <DataContext.Provider value={data}>
            {props.children}
        </DataContext.Provider>
    );
}; 

export default DataProvider;