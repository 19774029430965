
const ErrorPage = () => {
    return(
        <>
            <h1>An Error Ocurred!</h1>
            <p>Something went wrong</p>
        </>
    );
};

export default ErrorPage;