import { useContext } from "react";
import classes from "./Modal.module.css";
import DataContext from "../store/dataContext";
import { useNavigate, useParams } from "react-router-dom";
const Modal = (props) => {
    const {gallery} = useContext(DataContext);
    const params = useParams();    
    
    const navigate = useNavigate();

    function navigateHandler(){
        navigate("/photo-gallery"); // THIS IS ANOTHER WAY OF NAVIGATION
    };
    
    return(
        <>
            
                
            <div className={classes.overlay} onClick={navigateHandler}>
                <div className={classes.container}>
                    <button onClick={navigateHandler} className={classes.btn}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                    </button>
                    {
                        gallery.map((item)=>{
                            
                            return item.id === params.imgId&&<img key={item.id} className={classes.img} src={item.pic} alt={item.id}/>;
                        })
                    }
                </div>
            </div>
            
        </>
    );
};

export default Modal;