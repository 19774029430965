import Footer from "../components/Footer";
import Header from "../components/Header";
import classes from "./PhotoGallery.module.css";
import pic from "../IMG/dog7.png";
import { useContext } from "react";
import DataContext from "../store/dataContext";
import { Link } from "react-router-dom";
const PhotoGallery = () => {
    const {gallery} = useContext(DataContext);
    return <>
        <Header pic={pic} title="Gallery" aLabel="Check Them Out"/>
        <section className={classes.main}>
            
            {
                gallery.map((item)=>{
                    return(
                        <div className={item.style}>
                            <Link key={item.id} to={item.id}><img className={classes.img} src={item.pic} alt={`${item.id}`}/></Link>
                        </div>
                    );
                })
            }
            
        </section>
        <Footer/>
    </>
};

export default PhotoGallery;