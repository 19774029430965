import { createContext } from "react"




const DataContext = createContext({
    modal:false,
    modalHandler:(e)=>{},
    gallery:{}
});

export default DataContext;