import classes from "./Header.module.css";
const Header = (props) => {
    
    const scroll = () => {
        window.scrollTo({
            behavior: "smooth",
            top:700
        });
    };

    return(
    <header className={classes.hder}>
        
        <div className={classes.chachis}>
            <img src={props.pic} alt="header pic" className={classes.headerPic}/>
            <h1>{props.title}</h1>
            <button onClick={scroll}>{props.aLabel} <i className={`${classes.arrows} fas fa-angle-double-right btn-arrow`}></i></button> 
        </div>
    </header>
    );
};

export default Header;