import {createBrowserRouter, RouterProvider} from "react-router-dom"
import './App.css';
import RootLayout from "./routes/RootLayout";
import ErrorPage from "./routes/ErrorPage";
import HomePage from "./routes/HomePage.js";
import ContactUs from "./routes/ContactUsPage";
import PhotoGallery from "./routes/PhotoGallery";
import MeetFamily from "./routes/DogsPage";
import Faqs from "./routes/Faqs";
import Modal from "./components/Modal";
import DataProvider from "./store/dataProvider";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout/>,
    errorElement:<ErrorPage/>,
    children:[
      {
        index:true,
        element: <HomePage/>
      },
      {
        path:"services",
        element: <MeetFamily/>
      },
      {
        path:"faqs",
        element: <Faqs/>
      },
      {
        path:"contact-us",
        element:<ContactUs/>
      },
      {
        path:"photo-gallery",
        element:<PhotoGallery/>
      },
      {
        path:"photo-gallery/:imgId",
        element:<Modal/>
      }
    ]
  }
], {basename: "/"});

function App() {

  return (
    <DataProvider>
      <RouterProvider router={router}/>
    </DataProvider>
  );
}

export default App;
