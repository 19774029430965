import Footer from "../components/Footer";
import Header from "../components/Header";
import classes from "./DogsPages.module.css";
import family from "../IMG/dog1.jpg";
import p1 from "../IMG/dog2.jpg";
import p2 from "../IMG/cat1.jpg";
import p3 from "../IMG/p2.jpg";
import p4 from "../IMG/dog6.jpg";
const MeetFamily = () => {
    return (
        <>
            <Header pic={family} title="Our Family" href="#family" aLabel="Go Ahead"/>
            <section className={classes.main} id="#family">
                <div className={classes.d1}>
                    <img src={p1} alt="pic1"/>
                    <article className={classes.description}>
                        <h1>Name</h1>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Similique, placeat doloribus. Neque nemo illum dignissimos sapiente maxime enim eaque minus aliquam possimus nisi? Et mollitia sint, optio blanditiis quo eligendi.</p>
                    </article>
                </div>
                <div className={classes.d2}>
                    <img src={p2} alt="pic2"/>
                    <article className={classes.description}>
                        <h1>Name</h1>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Similique, placeat doloribus. Neque nemo illum dignissimos sapiente maxime enim eaque minus aliquam possimus nisi? Et mollitia sint, optio blanditiis quo eligendi.</p>
                    </article>
                </div>
                <div className={classes.d3}>
                    <img src={p3} alt="pic3"/>
                    <article className={classes.description}>
                        <h1>Name</h1>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Similique, placeat doloribus. Neque nemo illum dignissimos sapiente maxime enim eaque minus aliquam possimus nisi? Et mollitia sint, optio blanditiis quo eligendi.</p>
                    </article>
                </div>
                <div className={classes.d4}>
                    <img src={p4} alt="pic4"/>
                    <article className={classes.description}>
                        <h1>Name</h1>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Similique, placeat doloribus. Neque nemo illum dignissimos sapiente maxime enim eaque minus aliquam possimus nisi? Et mollitia sint, optio blanditiis quo eligendi.</p>
                    </article>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default MeetFamily;