import classes from "./ContactUsPage.module.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import pic from "../IMG/dog4.jpg";
import { useRef } from "react";
const ContactUs = () => {
    const name = useRef("");
    const phone = useRef("");
    const email = useRef("");
    const body = useRef("");

    const emailHandler = (e) => {
        e.preventDefault();
        const config = {
            Host : "smtp.elasticemail.c",
            Username : "amestatesales23@gmail.c",
            Password : "91C6D1FA11F97F9C4868463BDB3E28AA8B78",
            To : "a.mestatesales71@gmail.com",
            From : "amestatesales23@gmail.com",
            Subject : name.current.value,
            Body : "Email: " + email.current.value + "<br>" +
                    "Phone: " + phone.current.value + "<br>" +
                    "Message: " + body.current.value  
        };
        window.Email.send(config)
        .then(message => alert(message === "OK"? "message sent succesfully " + message:message))
        
    
    name.current.value = "";
    phone.current.value = "";
    body.current.value = "";
    email.current.value = "";
      }

    return <>
        <Header pic={pic} title="Contact Us" aLabel="Get In Touch"/>
        <section className={classes.main}>
            <h1>Send Us A Message!</h1>

            <form onSubmit={emailHandler}>
                <input ref={name} type="text" name="name" placeholder="Your Name" value={name.current.value}/>
                <input ref={email} type="text" name="email" placeholder="Your Email" value={email.current.value}/>
                <input ref={phone} type="text" name="phone" placeholder="Your Phone Number" value={phone.current.value}/>
                <textarea ref={body} name="body" cols="30" rows="10" placeholder="How Can I Help You?" value={body.current.value}></textarea>
                <button type="submit">Send</button>
            </form>
        </section>
        <Footer/>
    </>;
};

export default ContactUs;