import { Link } from "react-router-dom";
import classes from "./MainNavigation.module.css";
import logo from "../IMG/icononly.png";
import { useEffect, useState } from "react";
const MainNavigation = () => {
    const [nav, setNav] = useState(false);
    
    
    useEffect(()=>{
        const handleScroll = () => {
            if(window.scrollY > 50){
                setNav(true); 
            }else{
                setNav(false);
            }

        }
        
        
        window.addEventListener("scroll", handleScroll);
        

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
        
    },[]);
    
    return(
        <nav className={!nav? classes.list:classes.list2}>
            <section className={classes.sect}>
                <h3>Contact Us! ######</h3>
                <a href="www.facebook.com"><i className="fab fa-facebook-square"></i></a>
                <a href="www.instagram.com"><i className="fa-brands fa-instagram"></i></a>
            </section>
            <section className={classes.sect2}>
            {!nav &&<Link  to="/"><img src={logo} alt="logo" height="50" width="70" /></Link>}
            <ul>
                <li className={classes.homepageLink}><Link  to="/">HomePage</Link></li>
                <li><Link  to="services">Our Services</Link></li>
                <li><Link  to="faqs">FAQs</Link></li>
                <li><Link  to="contact-us">Contact Us</Link></li>
                <li><Link  to="photo-gallery">Photo Gallery</Link></li>
            </ul>
            </section>
        </nav>
    );
};

export default MainNavigation;