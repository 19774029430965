import headerPic from "../IMG/dog3.jpg";
import mainPic from "../IMG/p2.jpg";
import classes from "./HomePage.module.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
const HomePage = () => {
    
 return<>
    <Header title="Welcome To My Page" pic={headerPic} href="#subject" aLabel="About Us"/>
    <section className={classes.mainHomePage} id="subject">
        <img src={mainPic} alt="junior & dog"/>
        <div className={classes.description}>
            <h2>Description</h2>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Similique, placeat doloribus. Neque nemo illum dignissimos sapiente maxime enim eaque minus aliquam possimus nisi? Et mollitia sint, optio blanditiis quo eligendi.</p>
        </div>
    </section>
    <Footer/>
 </>;
};

export default HomePage;